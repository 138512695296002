class BstTabs {
  constructor(el) {
    this.$el = el;
    this.$activeTabControlEl = this.$el.querySelector('button.bst-tabs__button--active');
    this.$activeTabEl = this.$el.querySelector('.bst-tabs__tab--active');
    this.$tabControlElements = Array.from(this.$el.querySelectorAll('.bst-tabs__button')) || [];
    this.$tabElements = Array.from(this.$el.querySelectorAll('.bst-tabs__tab')) || [];
    this.activeTab = this.$activeTabControlEl
      ? this.$tabControlElements.indexOf(this.$activeTabControlEl)
      : -1;
    this.$el.classList.add('bst-tabs--bound');

    if (this.$tabControlElements.length > 0
        && this.$tabElements.length === this.$tabControlElements.length) {
      this.InitEvents();
      this.CheckForInitalActiveTab();
    }
  }

  CheckForInitalActiveTab() {
    if (this.activeTab < 0) {
      this.$tabControlElements[0].click();
    }
    if (!this.$activeTabEl) {
      this.$activeTabEl = this.$tabElements[this.activeTab];
      this.$activeTabEl.classList.add('bst-tabs__tab--active');
    }
  }

  InitEvents() {
    this.$tabControlElements.forEach((tabControlEl) => {
      tabControlEl.addEventListener('click', (e) => {
        e.preventDefault();
        if (this.$activeTabControlEl) {
          this.$activeTabControlEl.classList.remove('bst-tabs__button--active');
        }
        tabControlEl.classList.add('bst-tabs__button--active');
        this.$activeTabControlEl = tabControlEl;
        this.activeTab = this.$tabControlElements.indexOf(this.$activeTabControlEl);
        if (this.$activeTabEl) {
          this.$activeTabEl.classList.remove('bst-tabs__tab--active');
        }
        this.$activeTabEl = this.$tabElements[this.activeTab];
        this.$activeTabEl.classList.add('bst-tabs__tab--active');

        window.dispatchEvent(new Event('resize'));
      });
    });
  }
}

const createBstTabs = el => new BstTabs(el);

const bindBstTabs = () => {
  document.querySelectorAll('.bst-tabs:not(.bst-tabs--bound)').forEach((el) => {
    createBstTabs(el);
  });
};

bindBstTabs();
