class IndicatorControl {
  constructor(el) {
    this.$el = el;
    this.$indicators = this.$el.querySelectorAll('.indicator-control__indicator');
    this.$indicatorsEl = this.$el.querySelector('.indicator-control__indicators');
    this.$nextButton = this.$el.querySelector('.indicator-control__next');
    this.$prevButton = this.$el.querySelector('.indicator-control__prev');

    if (this.$indicators.length > 0) {
      const activeIndicatorEl = this.$el.querySelector('.indicator-control__indicator--active');
      this.activeIndicator = activeIndicatorEl
        ? Array.prototype.indexOf.call(this.$indicators, activeIndicatorEl)
        : 0;
    } else {
      this.activeIndicator = -1;
    }
    this.$el.classList.add('indicator-control--bound');
    this.initEvents();
    this.bindIndicators();
    this.setActiveIndicator(this.activeIndicator);
    this.checkVisibility();
  }

  bindIndicators() {
    this.$el.querySelectorAll('.indicator-control__indicator:not(.indicator-control__indicator--bound)').forEach((indicatorEl) => {
      indicatorEl.addEventListener('click', (e) => {
        e.preventDefault();
        this.activeIndicator = Array.prototype.indexOf.call(this.$indicators, indicatorEl);
        this.setActiveIndicator(this.activeIndicator);
      });
      indicatorEl.classList.add('indicator-control__indicator--bound');
    });
  }

  checkVisibility() {
    if (this.$indicators.length < 2) {
      this.$el.classList.add('indicator-control--hidden');
    } else {
      this.$el.classList.remove('indicator-control--hidden');
    }
  }

  initEvents() {
    this.$nextButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.setActiveIndicator(this.activeIndicator + 1);
    });
    this.$prevButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.setActiveIndicator(this.activeIndicator - 1);
    });
    this.$el.addEventListener('indicator-control-set-active', (e) => {
      const activeIndicator = e.detail && e.detail.activeIndicator !== undefined
        ? e.detail.activeIndicator
        : 0;
      const dontDispatch = e.detail && e.detail.dontDispatch;
      this.setActiveIndicator(activeIndicator, dontDispatch);
    });
  }

  setActiveIndicator(indicatorNumber, dontDispatch) {
    if (indicatorNumber > -1) {
      if (this.$indicators[indicatorNumber]) {
        this.activeIndicator = indicatorNumber;
        const activeIndicatorEl = this.$el.querySelector('.indicator-control__indicator--active');
        if (activeIndicatorEl) {
          activeIndicatorEl.classList.remove('indicator-control__indicator--active');
        }
        this.$indicators[indicatorNumber].classList.add('indicator-control__indicator--active');
        if (!dontDispatch) {
          this.$el.dispatchEvent(new CustomEvent('indicator-control-active-change', {
            bubbles: false,
            detail: {
              activeIndicator: this.activeIndicator,
            },
          }));
        }
      }
      this.$nextButton.disabled = this.$indicators.length === (this.activeIndicator + 1);
      this.$prevButton.disabled = this.activeIndicator === 0;
    }
  }
}

const createIndicatorControl = el => new IndicatorControl(el);

const bindIndicatorControls = () => {
  document.querySelectorAll('.indicator-control:not(.indicator-control--bound)').forEach((el) => {
    createIndicatorControl(el);
  });
};

bindIndicatorControls();
